import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
} from "react";

import { appConfig } from "@/constants";
import { type CoinBalance, EnvironmentNames } from "@/types";
import { notifyError } from "@/utils/monitoring";
import { useTranslation } from "react-i18next";

type FiatPrice = {
  usd: number;
  usd_24h_change: number;
};

const coinGeckoIds = [
  "ethereum",
  "immutable-x",
  "gods-unchained",
  "guild-of-guardians",
  "usd-coin",
  "tether",
  "illuvium",
  "metalcore",
  "nifty-league",
] as const;
type CoinGeckoId = (typeof coinGeckoIds)[number];
type CryptoFiatPrices = Record<string, FiatPrice>;
type CoinGeckoResponse = { data: Record<CoinGeckoId, FiatPrice> };

export type FiatEquivalentPrice = {
  currency: string;
  amount: string;
  amountBN: BigNumber;
  prefixSymbol: string;
  usd24HrChange: number;
};

interface Context {
  fiatPrices?: CryptoFiatPrices | undefined;
  getTotalFiatBalance: (
    coinBalances: CoinBalance[],
  ) => FiatEquivalentPrice | undefined;
  getFormattedPrice: (
    symbol: string | undefined,
    decimals: number | undefined,
    amount: string,
  ) => string;
  buildFiatEquivalentPrice: (
    tokenPrice: string,
    decimals: number,
    tokenSymbol: string,
    fiatPrices: CryptoFiatPrices | undefined,
    fiatCurrency?: string,
  ) => FiatEquivalentPrice | undefined;
}

export const FiatPricingContext = createContext<Context>({
  fiatPrices: undefined,
  getFormattedPrice: () => "",
  buildFiatEquivalentPrice: () => undefined,
  getTotalFiatBalance: () => undefined,
});

export const useFiatPricingContext = (): Context =>
  useContext(FiatPricingContext);

async function getCheckoutApiPrices(fiatSymbol = "usd") {
  const idsParam = coinGeckoIds?.join(",");
  const currenciesParam = fiatSymbol.toLowerCase();
  const include24hrChangeParam = "true"; // always fetch 24hr change

  const baseUrl =
    appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
      ? "https://checkout-api.immutable.com"
      : `https://checkout-api.${appConfig.ENVIRONMENT}.immutable.com`;
  const path = `/v1/fiat/conversion?ids=${idsParam}&currencies=${currenciesParam}&include_24hr_change=${include24hrChangeParam}`;

  const { data } = (await axios.get(baseUrl + path)) as CoinGeckoResponse;
  return data;
}

// TODO @pano @ji: This is actually only a Fiat pricing provider for tokens that Moonpay supports :(
export function FiatPricingProvider({
  children,
  fiatSymbol = "usd",
}: PropsWithChildren<{ fiatSymbol?: string }>) {
  const { t } = useTranslation();

  const { data } = useQuery<CryptoFiatPrices>({
    queryKey: ["checkoutApiPrices", fiatSymbol],
    refetchInterval: 1000 * 60 * 5, // refetch every 5 minutes
    queryFn: () => getCheckoutApiPrices(fiatSymbol),
    select: (data) => ({
      eth: data.ethereum,
      imx: data["immutable-x"],
      wimx: data["immutable-x"],
      timx: data["immutable-x"],
      gods: data["gods-unchained"],
      usdc: data["usd-coin"],
      gog: data["guild-of-guardians"],
      usdt: data["usd-coin"],
      ilv: data.illuvium,
      mcg: data.metalcore,
      nftl: data["nifty-league"],
    }),
  });

  const getFormattedPrice = useCallback(
    (
      symbol: string | undefined,
      decimals: number | undefined,
      amount: string,
    ) => {
      if (!symbol || !decimals) {
        return amount;
      }

      const fiatPrice = data ? data[symbol.toLocaleLowerCase()] : undefined;
      const usdValue = fiatPrice?.usd;

      if (usdValue) {
        const price = new BigNumber(amount)
          .multipliedBy(usdValue)
          .div(10 ** decimals, 10)
          .toFormat(2);
        if (price === "0.00") {
          return t("approximate_usd_less_than_0_01");
        }
        if (price && price !== "NaN") {
          return t("approximate_usd_value", { amount: price });
        }
      }

      const formattedAmount = new BigNumber(amount)
        .div(10 ** decimals, 10)
        .toFormat(2);
      if (formattedAmount && formattedAmount !== "NaN") {
        return `${symbol} ${formattedAmount}`;
      }
      return `${symbol} ${amount}`;
    },
    [data, t],
  );

  const getTotalFiatBalance = useCallback(
    (coinBalances: CoinBalance[]): FiatEquivalentPrice | undefined =>
      getTotalFiat(coinBalances, data),
    [data],
  );

  return (
    <FiatPricingContext.Provider
      value={{
        fiatPrices: data,
        getFormattedPrice,
        buildFiatEquivalentPrice,
        getTotalFiatBalance,
      }}
    >
      {children}
    </FiatPricingContext.Provider>
  );
}

export const buildFiatEquivalentPrice = (
  tokenPrice: string,
  tokenDecimals: number,
  tokenSymbol: string,
  fiatPrices: CryptoFiatPrices | undefined,
  fiatCurrency = "usd",
) => {
  if (!fiatPrices) {
    return;
  }

  if (!tokenSymbol) {
    const tokenSymbolError = new Error("Token Symbol does not exist");
    notifyError(tokenSymbolError, "appError");
    return;
  }

  const symb = tokenSymbol.toLowerCase();

  if (!(symb in fiatPrices)) {
    return;
  }

  const fiatPrice = fiatPrices[symb] as FiatPrice | undefined;

  const usdValue = fiatPrice?.usd;
  if (!usdValue) {
    return;
  }

  const fiatEquivalentRaw = new BigNumber(tokenPrice)
    .multipliedBy(usdValue)
    .dividedBy(new BigNumber(10).pow(tokenDecimals));

  let formattedAmount: string;
  if (
    fiatEquivalentRaw.isGreaterThan(0) &&
    fiatEquivalentRaw.isLessThan(0.01)
  ) {
    formattedAmount = "< $0.01";
  } else {
    formattedAmount = fiatEquivalentRaw.toFormat(2);
  }

  return {
    currency: `${
      fiatEquivalentRaw.isGreaterThan(0) ? "" : "~ "
    }${fiatCurrency.toUpperCase()}`,
    amount: formattedAmount !== "NaN" ? formattedAmount : "",
    amountBN: fiatEquivalentRaw,
    prefixSymbol: fiatCurrency === "usd" ? "$" : "",
    usd24HrChange: fiatPrice.usd_24h_change,
  };
};

export const getTotalFiat = (
  coinBalances: CoinBalance[],
  fiatPrices: CryptoFiatPrices | undefined,
): FiatEquivalentPrice | undefined => {
  if (!fiatPrices) return undefined;
  return coinBalances.reduce(
    (acc, coinBalance) => {
      const fiatEquivalentPrice = buildFiatEquivalentPrice(
        coinBalance.balance,
        coinBalance.decimals,
        coinBalance.symbol,
        fiatPrices,
      );
      if (!fiatEquivalentPrice) return acc;

      const amountBN = fiatEquivalentPrice.amountBN.plus(acc.amountBN);

      const usd24HrChange = amountBN.isZero()
        ? acc.usd24HrChange
        : acc.amountBN
            .multipliedBy(acc.usd24HrChange)
            .plus(
              fiatEquivalentPrice.amountBN.multipliedBy(
                fiatEquivalentPrice.usd24HrChange,
              ),
            )
            .dividedBy(amountBN)
            .toNumber();

      return {
        amountBN,
        amount: amountBN.toFormat(2),
        currency: acc.currency,
        prefixSymbol: acc.prefixSymbol,
        usd24HrChange: usd24HrChange,
      };
    },
    {
      amount: "0",
      amountBN: BigNumber(0),
      currency: "usd",
      prefixSymbol: "$",
      usd24HrChange: 0,
    },
  );
};
