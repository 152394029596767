import { useWindowSizeStore } from "@biom3/react";

export const BREAKPOINTS = {
  xSmall: 385,
  small: 430,
  medium: 768,
  large: 950,
  xLarge: 1240,
  xxLarge: 1440,
  xxxLarge: 1920,
};

const useScreenWidth = () => {
  const { state: width } = useWindowSizeStore((store) => store.width);
  return width;
};

export function useIsLessThanXSmallScreen() {
  const width = useScreenWidth();
  return typeof width === "number" && width < BREAKPOINTS.xSmall;
}

export function useIsLessThanMediumScreen() {
  const width = useScreenWidth();
  return typeof width === "number" && width < BREAKPOINTS.medium;
}

export function useIsLessThanLargeScreen() {
  const width = useScreenWidth();
  return typeof width === "number" && width < BREAKPOINTS.large;
}

export function useIsLessThanXLargeScreen() {
  const width = useScreenWidth();
  return typeof width === "number" && width < BREAKPOINTS.xLarge;
}

export function useIsLessThanXXLargeScreen() {
  const width = useScreenWidth();
  return typeof width === "number" && width < BREAKPOINTS.xxLarge;
}

export function useIsLessThanXXXLargeScreen() {
  const width = useScreenWidth();
  return typeof width === "number" && width < BREAKPOINTS.xxxLarge;
}
