import { config } from "@imtbl/sdk";
import { z } from "zod";

import { type ChainAddress, EnvironmentNames } from "@/types";

const toImtblSdkEnv = (env: "sandbox" | "production") =>
  ({
    sandbox: config.Environment.SANDBOX,
    production: config.Environment.PRODUCTION,
  })[env];

const ethereumAddress = () =>
  z.custom<ChainAddress>(
    (val) => typeof val === "string" && val.startsWith("0x"),
  );

export const schema = z
  .object({
    ALCHEMY_API_KEY: z.string().optional(),
    ASSET_BASE_URL: z.string().url(),
    AUTH0_CLIENT_ID: z.string(),
    AUTH0_LOGOUT_REDIRECT_PLAY_PORTAL_URI: z.string().url(),
    AUTH0_REDIRECT_PLAY_PORTAL_URI: z.string().url(),
    BRAZE_API_KEY: z.string(),
    BRAZE_API_ENDPOINT: z.string(),
    ENVIRONMENT: z.nativeEnum(EnvironmentNames),
    IMAGE_RESIZER_URL: z.string().url(),
    IMMUTABLE_BASE_URI: z.string().url(),
    IMX_CONTRACT_ADDRESS: ethereumAddress(),
    LAUNCH_DARKLY_CLIENT_ID: z.string(),
    LAYERSWAP_CLIENT_ID: z.string(),
    MOONPAY_API_KEY: z.string(),
    NEW_RELIC_APPLICATION_ID: z.string(),
    NEW_RELIC_LICENSE_KEY: z.string(),
    SEGMENT_APP_NAME: z.string(),
    SEGMENT_KEY: z.string(),
    TOKEN_ICONS_URL: z.string().url(),
    WALLET_CONNECT_PROJECT_ID: z.string().optional(),
    GTM_CONTAINER_ID: z.string(),

    // HLS
    HLS_DOMAIN: z.string().url(),

    // Tokens
    IMMUTABLE_API_URL: z.string().url(),
    GA_TRACKING_ID: z.string().optional(),
    GA_URL: z
      .string()
      .url()
      .transform((gaUrl) => `${gaUrl}/gtag/js`),
    SEGMENT_WRITE_KEY: z.string().length(32).optional(),
    CHAIN_NAME: z.enum([
      "imtbl-zkevm-devnet",
      "imtbl-zkevm-testnet",
      "imtbl-zkevm-mainnet",
    ]),
    CHAIN_ID: z
      .enum(["13473", "13371", "15003"])
      .transform((chainID) => +chainID),
    IMMUTABLE_SDK_ENVIRONMENT: z
      .enum(["sandbox", "production"])
      .transform(toImtblSdkEnv),
    BLOCK_EXPLORER_URL: z.string().url(),
    IMX_COMMUNITY_URL: z.string().url(),
    GEMS_GAME_CONTRACT_ADDRESS: z.string().length(42),
    PROMO_TILES_CMS_URL: z
      .string()
      .url()
      .transform((url) => new URL(url)),
    QUESTS_CMS_URL: z
      .string()
      .url()
      .transform((url) => new URL(url)),
    QUEST_SECTIONS_CMS_URL: z
      .string()
      .url()
      .transform((url) => new URL(url)),

    // Social links
    DISCORD_CLIENT_ID: z.string(),
    DISCORD_REDIRECT_URI: z.string().url(),
  })
  .refine(
    (data) =>
      data.ENVIRONMENT === EnvironmentNames.DEV ||
      ((data.ENVIRONMENT === EnvironmentNames.PRODUCTION ||
        data.ENVIRONMENT === EnvironmentNames.SANDBOX) &&
        data.WALLET_CONNECT_PROJECT_ID !== undefined),
    {
      message:
        "WALLET_CONNECT_PROJECT_ID must be set in production or sandbox environments",
    },
  );

export const appConfigFn = () =>
  schema.parse({
    ALCHEMY_API_KEY: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY,
    ASSET_BASE_URL: process.env.NEXT_PUBLIC_ASSET_BASE_URL,
    AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    AUTH0_LOGOUT_REDIRECT_PLAY_PORTAL_URI:
      process.env.NEXT_PUBLIC_AUTH0_LOGOUT_REDIRECT_PLAY_PORTAL_URI,
    AUTH0_REDIRECT_PLAY_PORTAL_URI:
      process.env.NEXT_PUBLIC_AUTH0_REDIRECT_PLAY_PORTAL_URI,
    BRAZE_API_KEY: process.env.NEXT_PUBLIC_BRAZE_API_KEY,
    BRAZE_API_ENDPOINT: process.env.NEXT_PUBLIC_BRAZE_API_ENDPOINT,
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    IMAGE_RESIZER_URL: process.env.NEXT_PUBLIC_IMAGE_RESIZER_URL,
    IMMUTABLE_BASE_URI: process.env.NEXT_PUBLIC_IMMUTABLE_BASE_URI,
    IMX_CONTRACT_ADDRESS: process.env.NEXT_PUBLIC_IMX_CONTRACT_ADDRESS,
    LAUNCH_DARKLY_CLIENT_ID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
    LAYERSWAP_CLIENT_ID: process.env.NEXT_PUBLIC_LAYERSWAP_CLIENT_ID,
    MOONPAY_API_KEY: process.env.NEXT_PUBLIC_MOONPAY_API_KEY,
    NEW_RELIC_APPLICATION_ID: process.env.NEXT_PUBLIC_NEW_RELIC_APPLICATION_ID,
    NEW_RELIC_LICENSE_KEY: process.env.NEXT_PUBLIC_NEW_RELIC_LICENSE_KEY,
    SEGMENT_APP_NAME: process.env.NEXT_PUBLIC_SEGMENT_APP_NAME,
    SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    TOKEN_ICONS_URL: process.env.NEXT_PUBLIC_TOKEN_ICONS_URL,
    WALLET_CONNECT_PROJECT_ID:
      process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
    GTM_CONTAINER_ID: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,

    // Tokens
    IMMUTABLE_API_URL: process.env.NEXT_PUBLIC_IMMUTABLE_API_URL,
    GA_TRACKING_ID: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    GA_URL: process.env.NEXT_PUBLIC_GA_URL,
    SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    CHAIN_NAME: process.env.NEXT_PUBLIC_CHAIN_NAME,
    CHAIN_ID: process.env.NEXT_PUBLIC_CHAIN_ID,
    IMMUTABLE_SDK_ENVIRONMENT:
      process.env.NEXT_PUBLIC_IMMUTABLE_SDK_ENVIRONMENT,
    BLOCK_EXPLORER_URL: process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URL,
    IMX_COMMUNITY_URL: process.env.NEXT_PUBLIC_IMX_COMMUNITY_URL,
    PROMO_TILES_CMS_URL: process.env.NEXT_PUBLIC_PROMO_TILES_CMS_URL,
    QUESTS_CMS_URL: process.env.NEXT_PUBLIC_QUESTS_CMS_URL,
    QUEST_SECTIONS_CMS_URL: process.env.NEXT_PUBLIC_QUEST_SECTIONS_CMS_URL,
    GEMS_GAME_CONTRACT_ADDRESS:
      process.env.NEXT_PUBLIC_GEMS_GAME_CONTRACT_ADDRESS,

    // HLS
    HLS_DOMAIN: process.env.NEXT_PUBLIC_HLS_DOMAIN,

    // Social links
    DISCORD_CLIENT_ID: process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID,
    DISCORD_REDIRECT_URI: process.env.NEXT_PUBLIC_DISCORD_REDIRECT_URI,
  });
