import { useRouter } from "next/router";
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

interface RouteContextType {
  previousRoute: string | null;
}

const RouteContext = createContext<RouteContextType | undefined>(undefined);

export const RouteProvider = ({ children }: PropsWithChildren) => {
  const [previousRoute, setPreviousRoute] = useState<string | null>(null);
  const router = useRouter();
  const currentPathRef = useRef(router.asPath);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setPreviousRoute(currentPathRef.current);
      currentPathRef.current = url;
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  return (
    <RouteContext.Provider value={{ previousRoute }}>
      {children}
    </RouteContext.Provider>
  );
};

export const usePreviousRoute = (): string | null => {
  const context = useContext(RouteContext);
  if (!context) {
    throw new Error("usePreviousRoute must be used within a RouteProvider");
  }
  return context.previousRoute;
};
