import {
  type StandardAnalyticsActions,
  type StandardAnalyticsControlTypes,
  createAnalytics,
} from "@imtbl/react-analytics";

import { appConfig } from "@/constants";
import { usePreviousRoute } from "@/context";

export type DashboardAnalyticsJourneyType =
  | "Page"
  | "Profile"
  | "Activity"
  | "Balances"
  | "Collections"
  | "Games"
  | "Wallets"
  | "AssetTransfer"
  | "Rewards"
  | "Onboarding"
  | "Gems"
  | "Split"
  | "Listing"
  | "Bid"
  | "Connect"
  | "Disconnect"
  | "Claim"
  | "Ecosystem"
  | "Quest"
  | "QuestCTA"
  | "QuestRulesCTA"
  | "QuestSecondary"
  | "QuestRuleSupport"
  | "QuestOpenRules"
  | "QuestCloseRules"
  | "SignUp"
  | QuestAnalyticsType
  | QuestsNavigationJourney
  | WishlistUserJourney
  | UserChestJourney;

export type QuestAnalyticsType =
  | "TombOfHeroesQuest"
  | "TradeZkEvmBonus"
  | "TradeZkEvmIMXBonus"
  | "IlluviumBonus"
  | "BabySharkBonus"
  | "HuntersOnChainBonus"
  | "MedievalEmpiresBonus"
  | "MedievalEmpiresLevel8Bonus"
  | "SpaceNationWhitelistSpots"
  | "SpaceNationLayer3Quest"
  | "CallOfTheVoydRaffle"
  | "SetUpPassportQuest"
  | "PoolMastersQuest"
  | "TreeverseQuest"
  | "CounterFireQuest"
  | "RavenQuestQuest"
  | "SomnisBonus"
  | "GoGPromoTileJadey"
  | "PoolMastersManualQuest"
  | "ParadiseTycoonQuest"
  | "ImmortalRisingQuest"
  | "RebelBotsQuest"
  | "RavenQuestPromo"
  | "RavenQuestInGame"
  | "SomnisRumbleRushPromo";

export type UserChestJourney = "TreasureChest";

export type WishlistUserJourney = "BadMadRobots";

type QuestsNavigationJourney =
  | "FeaturedQuests"
  | "MainQuests"
  | "SideQuests"
  | "PromoTiles";

export type Component =
  | "ConnectionProvider"
  | "Claim"
  | "Dashboard"
  | "GameShowcase"
  | "QuestTile"
  | "QuestTileModal"
  | "HowItWorksModal"
  | "QuestsNavigation"
  | "PromoTile"
  | "RecommendedQuests"
  | "WishlistTile"
  | "ChestContainer"
  | "Layerswap";

export type DashboardAnalyticsScreenType = string;
export type DashboardAnalyticsControlType = string;

export type DashboardAnalyticsBaseType = {
  userJourney: DashboardAnalyticsJourneyType;
  screen: DashboardAnalyticsScreenType;
};

type StandardAnalyticsControlType =
  | StandardAnalyticsControlTypes
  | "Effect"
  | "Card"
  | "Modal"
  | "Group";

export type StandardAnalyticsActionsType =
  | StandardAnalyticsActions
  | TreasureChestActions
  | "Changed"
  | "Closed"
  | "Scrolled";

type TreasureChestActions = "Granted" | "Claimed";

type AnalyticsExtras = {
  [key: string]: unknown;
};

type PageProps = {
  screen: string;
  userJourney: DashboardAnalyticsJourneyType;
  action?: StandardAnalyticsActionsType;
  extras?: AnalyticsExtras;
};

type TrackProps =
  | {
      screen: string;
      userJourney: DashboardAnalyticsJourneyType;
      action: StandardAnalyticsActionsType;
      extras?: AnalyticsExtras;
    }
  | {
      screen: string;
      userJourney: DashboardAnalyticsJourneyType;
      control: string;
      controlType: StandardAnalyticsControlType;
      action?: StandardAnalyticsActionsType;
      extras?: AnalyticsExtras;
    };

const { AnalyticsProvider, useAnalytics } = createAnalytics<
  DashboardAnalyticsJourneyType,
  DashboardAnalyticsScreenType,
  DashboardAnalyticsControlType,
  StandardAnalyticsControlType,
  StandardAnalyticsActionsType
>({
  writeKey: appConfig.SEGMENT_KEY,
  appName: "PassportDashboard",
});

function useAnalyticsWithReferrer() {
  const { page: basePage, track: baseTrack, ...rest } = useAnalytics();
  const previousRoute = usePreviousRoute();
  const referrer = previousRoute ?? "";

  function useReferrerContext(extras: AnalyticsExtras = {}): AnalyticsExtras {
    return {
      ...extras,
      referrer,
    };
  }

  function page(props: PageProps) {
    // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
    const extrasWithReferrer = useReferrerContext(props.extras);
    return basePage({
      ...props,
      extras: extrasWithReferrer,
    });
  }

  function track(props: TrackProps) {
    // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
    const extrasWithReferrer = useReferrerContext(props.extras);
    return baseTrack({
      ...props,
      extras: extrasWithReferrer,
    });
  }

  return { ...rest, page, track };
}

export { AnalyticsProvider, useAnalytics, useAnalyticsWithReferrer };
