import type { passport, x } from "@imtbl/sdk";
import { providers } from "ethers";
import jwtDecode from "jwt-decode";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useAnalyticsWithReferrer, useImmutableProvider } from "@/context";
import type { ChainAddress } from "@/types";
import { notifyError, setNewRelicUserId } from "@/utils/monitoring";
import { usePrevious } from "@biom3/react";

export type PassportState = {
  authenticated: boolean;
  newZkEvmUser: boolean;
  starkExRegistered: boolean;
  zkEvmRegistered: boolean;
  ready: boolean;
};

type DecodedAccessToken = {
  imx_eth_address?: string;
  imx_stark_address?: string;
  imx_user_admin_address?: string;
  zkevm_eth_address?: string;
  zkevm_user_admin_address?: string;
};

export type PassportContext = {
  logout: () => void;
  loginCallback: () => void;
  triggerLogin: () => void;
  triggerLoginWithCallback: (callback: () => void) => void;
  userInfo?: passport.UserProfile;
  passportState: PassportState;
  isLoggedIn: boolean;
  walletAddress?: ChainAddress;
  zkEvmProvider?: providers.Web3Provider;
};

export const PassportContext = createContext<PassportContext>({
  loginCallback: () => undefined,
  triggerLogin: () => undefined,
  triggerLoginWithCallback: () => undefined,
  logout: () => undefined,
  isLoggedIn: false,
  passportState: {
    authenticated: false,
    newZkEvmUser: false,
    starkExRegistered: false,
    zkEvmRegistered: false,
    ready: false,
  },
});

export function PassportProvider({ children }: PropsWithChildren) {
  const [provider, setProvider] = useState<x.IMXProvider | undefined>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [zkEvmProvider, setZkEvmProvider] = useState<
    providers.Web3Provider | undefined
  >();
  const [userInfo, setUserInfo] = useState<passport.UserProfile>();
  const [walletAddress, setWalletAddress] = useState<ChainAddress>();
  const [userProfile, setUserProfile] = useState<passport.UserProfile | null>();
  const previousUserProfile = usePrevious(userProfile);
  const [passportState, setPassportState] = useState<PassportState>({
    authenticated: false,
    newZkEvmUser: false,
    starkExRegistered: false,
    zkEvmRegistered: false,
    ready: false,
  });
  const previousPassportState = usePrevious(passportState);
  const immutableProvider = useImmutableProvider();
  const analytics = useAnalyticsWithReferrer();
  const { passportClient } = immutableProvider;
  const triggerLogin = useCallback(async () => {
    try {
      setUserProfile(await passportClient.login());
    } catch (error) {
      notifyError(error, "login");
    }
  }, [passportClient]);

  const triggerLoginWithCallback = useCallback(
    async (callback: () => void) => {
      try {
        setUserProfile(await passportClient.login());
        if (callback) callback();
      } catch (error) {
        notifyError(error, "login");
      }
    },
    [passportClient],
  );

  const logout = useCallback(async () => {
    try {
      await passportClient.logout();
    } catch (e) {
      notifyError(e, "logout");
    }
    setUserProfile(undefined);
    setUserInfo(undefined);
    setWalletAddress(undefined);
    setNewRelicUserId(null);
    setPassportState({
      authenticated: false,
      newZkEvmUser: false,
      starkExRegistered: false,
      zkEvmRegistered: false,
      ready: true,
    });
    setIsLoggedIn(false);
  }, [passportClient]);

  const loginCallback = useCallback(
    async () => passportClient.loginCallback(),
    [passportClient],
  );

  const getImxProvider = useCallback(async () => {
    let passportProvider = provider;
    try {
      if (!passportProvider) {
        passportProvider = await passportClient.connectImx();
        setProvider(passportProvider);
      }
    } catch (error) {
      notifyError(error, "getImxProvider");
      throw error;
    }
    return passportProvider;
  }, [passportClient, provider]);

  // Check if a session exists on first initialisation
  useEffect(() => {
    const fetchUser = async () => {
      try {
        setZkEvmProvider(
          new providers.Web3Provider(passportClient.connectEvm()),
        );
        const profile = await passportClient.getUserInfo();
        if (profile) {
          setUserProfile(profile);
        } else {
          setIsLoggedIn(false);
          setPassportState({
            authenticated: false,
            newZkEvmUser: false,
            starkExRegistered: false,
            zkEvmRegistered: false,
            ready: true,
          });
        }
      } catch (e) {
        // Likely due to invalid refresh token, force a re-auth
        notifyError(e, "fetchUser");
        await logout();
      }
    };
    fetchUser();
  }, [passportClient, logout]);

  // Handle userProfile set
  useEffect(() => {
    const authenticate = async () => {
      if (previousUserProfile === userProfile || !userProfile) return;

      try {
        const idToken = await passportClient.getIdToken();
        const accessToken = await passportClient.getAccessToken();
        if (!!accessToken && !!idToken) {
          const imxProvider = await getImxProvider();

          // Currently no way to get this immediately from the SDK post login so we decode the access token for speed
          const decodedAccessToken = jwtDecode<DecodedAccessToken>(accessToken);
          const zkEvmRegistered =
            !!decodedAccessToken.zkevm_eth_address &&
            !!decodedAccessToken.zkevm_user_admin_address;

          if (zkEvmRegistered && zkEvmProvider) {
            // Need to call eth_requestAccounts to setup signer
            zkEvmProvider.send("eth_requestAccounts", []);
            setWalletAddress(
              decodedAccessToken.zkevm_eth_address as ChainAddress,
            );
          }

          const starkExRegistered = await imxProvider.isRegisteredOffchain();
          if (starkExRegistered) {
            setWalletAddress((await imxProvider.getAddress()) as ChainAddress);
          }
          analytics.identify(userProfile.sub, {
            name: userProfile?.nickname,
            email: userProfile?.email,
          });

          setUserInfo(userProfile);
          setNewRelicUserId(userProfile.sub);
          setPassportState({
            authenticated: true,
            newZkEvmUser: !zkEvmRegistered,
            starkExRegistered: starkExRegistered,
            zkEvmRegistered: zkEvmRegistered,
            ready: true,
          });
          setIsLoggedIn(true);
        } else {
          // Invalid session, no access or id token
          notifyError(new Error("Invalid session tokens"), "authenticate");
          await logout();
        }
      } catch (error) {
        console.error({ error });
        notifyError(error, "decodePassportToken");
        await logout();
      }
    };
    authenticate();
  }, [
    userProfile,
    previousUserProfile,
    passportClient,
    zkEvmProvider,
    getImxProvider,
    logout,
    analytics,
  ]);

  // Register off chain if necessary and get wallet address
  useEffect(() => {
    let starkExRegistered = passportState.starkExRegistered;
    let zkEvmRegistered = passportState.zkEvmRegistered;
    if (
      passportState.authenticated === previousPassportState?.authenticated ||
      passportState.authenticated !== true
    ) {
      return;
    }

    // ID-2462: Register each chain synchronously to prevent race conditions when updating Auth0 Metadata
    const register = async () => {
      try {
        const passportProvider = passportClient.connectEvm();
        if (!zkEvmRegistered) {
          const addresses = await passportProvider.request({
            method: "eth_requestAccounts",
          });
          zkEvmRegistered = true;
          // Update states now so the user can start using the Dashboard
          setWalletAddress(addresses[0]);
          setPassportState({
            authenticated: true,
            newZkEvmUser: passportState.newZkEvmUser,
            starkExRegistered: starkExRegistered,
            zkEvmRegistered: zkEvmRegistered,
            ready: true,
          });
          setIsLoggedIn(true);
        }
      } catch (error) {
        notifyError(error, "passportRegistration", { network: "zkEVM" });
      }
      try {
        const imxProvider = await getImxProvider();
        if (!starkExRegistered) {
          await imxProvider.registerOffchain();
          const address = (await imxProvider.getAddress()) as ChainAddress;
          starkExRegistered = true;
          setWalletAddress(address);
          setPassportState({
            authenticated: true,
            newZkEvmUser: passportState.newZkEvmUser,
            starkExRegistered: starkExRegistered,
            zkEvmRegistered: zkEvmRegistered,
            ready: true,
          });
          setIsLoggedIn(true);
        }
      } catch (error) {
        notifyError(error, "passportRegistration", { network: "StarkEx" });
      }
    };

    register();
  }, [passportState, passportClient, getImxProvider, previousPassportState]);

  const providerValues = useMemo(
    () => ({
      loginCallback,
      logout,
      triggerLogin,
      triggerLoginWithCallback,
      isLoggedIn,
      userInfo,
      walletAddress,
      passportState,
      zkEvmProvider,
    }),
    [
      loginCallback,
      logout,
      triggerLogin,
      triggerLoginWithCallback,
      isLoggedIn,
      userInfo,
      walletAddress,
      passportState,
      zkEvmProvider,
    ],
  );

  return (
    <PassportContext.Provider value={providerValues}>
      {children}
    </PassportContext.Provider>
  );
}

export function usePassportProvider() {
  const {
    loginCallback,
    triggerLogin,
    triggerLoginWithCallback,
    logout,
    isLoggedIn,
    userInfo,
    walletAddress,
    passportState,
    zkEvmProvider,
  } = useContext(PassportContext);
  return {
    loginCallback,
    triggerLogin,
    triggerLoginWithCallback,
    logout,
    isLoggedIn,
    userInfo,
    walletAddress,
    passportState,
    zkEvmProvider,
  };
}
