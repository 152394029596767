import {
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

export type FilterOption = {
  key: string;
  label: string;
  value: string;
  originalValues?: string[];
};

export type FilterConfig = {
  title: string;
  filterKey: string;
  options: FilterOption[];
};

export type Filters = Record<string, string[]>;

export type FilterContext = {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  filterConfig: Record<string, FilterConfig>;
  setFilterConfig: (filterConfig: Record<string, FilterConfig>) => void;
};

const filterContext = createContext<FilterContext | null>(null);

const defaultFilters = {
  platform: [],
  genre: [],
  search: [],
};

export function FilterProvider({ children }: PropsWithChildren) {
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [filterConfig, setFilterConfig] = useState<
    Record<string, FilterConfig>
  >({});

  return (
    <filterContext.Provider
      value={{
        filters,
        setFilters,
        filterConfig,
        setFilterConfig,
      }}
    >
      {children}
    </filterContext.Provider>
  );
}

export const useFilter = () => {
  const context = useContext(filterContext);
  if (!context) {
    throw new Error("useFilter must be used within a FilterProvider");
  }
  return context;
};
