import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAccessToken } from "@/hooks";
import type { PartialProfilePrefences, ProfilePreferences } from "@/types";
import { notifyError } from "@/utils/monitoring";
import { authorizedGet, authorizedPatch } from "@/utils/request";
import { usePrevious } from "@biom3/react";
import { usePassportProvider } from "./PassportProvider";

const PREFERENCES_PATH = "/passport-profile/v1/preferences";

export const PreferencesContext = createContext<{
  data: ProfilePreferences;
  update: (preference: PartialProfilePrefences) => void;
}>({
  data: {
    marketing_consent: "subscribed",
    onboard: false,
    reveal_address: false,
    hide_activation_badges: {
      balances: true,
      inventory: true,
      external_wallets: true,
    },
  },
  update: () => {},
});

const usePreferences = () => {
  const ctx = useContext(PreferencesContext);
  if (!ctx) {
    throw new Error("usePreferences must be used within a PreferencesProvider");
  }
  return ctx;
};

const PreferencesProvider = ({ children }: PropsWithChildren) => {
  const getAccessToken = useAccessToken();
  const { passportState } = usePassportProvider();
  const [preferences, setPreferences] = useState<ProfilePreferences>({
    marketing_consent: "subscribed",
    onboard: false,
    reveal_address: false,
    hide_activation_badges: {
      balances: true,
      inventory: true,
      external_wallets: true,
    },
  });
  const previousAuthenticated = usePrevious(passportState.authenticated);

  useEffect(() => {
    const getPrefs = async () => {
      if (passportState.authenticated && !previousAuthenticated) {
        try {
          const accessToken = await getAccessToken();
          if (!accessToken) return undefined;
          const prefs: ProfilePreferences = await authorizedGet(
            PREFERENCES_PATH,
            accessToken,
          );
          if (
            prefs.onboard === undefined &&
            (prefs.marketing_consent === undefined ||
              prefs.marketing_consent === "subscribed")
          ) {
            prefs.onboard = true;
            prefs.marketing_consent = "subscribed";
          }
          setPreferences(prefs);
        } catch (e) {
          notifyError(e, "getPreferences");
          console.error(e);
        }
      }
    };
    getPrefs();
  }, [passportState.authenticated, previousAuthenticated, getAccessToken]);

  const update = useCallback(
    async (preference: PartialProfilePrefences) => {
      const accessToken = await getAccessToken();
      if (!accessToken) return undefined;
      try {
        const newPrefs: ProfilePreferences = await authorizedPatch(
          PREFERENCES_PATH,
          preference,
          accessToken,
        );
        setPreferences(newPrefs);
      } catch (e) {
        notifyError(e, "updatePreferences");
        console.error(e);
      }
    },
    [getAccessToken],
  );

  return (
    <PreferencesContext.Provider
      value={{
        data: preferences,
        update,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export { PreferencesProvider, usePreferences };
