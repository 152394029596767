import { checkout } from "@imtbl/sdk";
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { appConfig } from "@/constants";
import { providers } from "ethers";
import { getCheckoutConfig } from "./CheckoutProvider";
import { useImmutableProvider } from "./ImmutableProvider";

export type CommerceContext = {
  commerceWidget:
    | checkout.Widget<typeof checkout.WidgetType.IMMUTABLE_COMMERCE>
    | undefined;
};

const commerceContext = createContext<CommerceContext | null>(null);

export function CommerceProvider({ children }: PropsWithChildren) {
  const [commerceWidget, setCommerceWidget] =
    useState<checkout.Widget<typeof checkout.WidgetType.IMMUTABLE_COMMERCE>>();

  const { passportClient } = useImmutableProvider();

  const environment = appConfig.ENVIRONMENT;

  useEffect(() => {
    (async () => {
      const checkoutSDK = new checkout.Checkout({
        passport: passportClient,
        ...getCheckoutConfig(environment),
      });

      const widgetsFactory = await checkoutSDK.widgets({
        config: {
          theme: checkout.WidgetTheme.DARK,
        },
      });

      const commerceWidget = widgetsFactory.create(
        checkout.WidgetType.IMMUTABLE_COMMERCE,
        {
          provider: new providers.Web3Provider(passportClient.connectEvm()),
        },
      );
      setCommerceWidget(commerceWidget);
    })();
  }, [environment, passportClient]);

  return (
    <commerceContext.Provider value={{ commerceWidget }}>
      {children}
    </commerceContext.Provider>
  );
}

export const useCommerce = () => {
  const context = useContext(commerceContext);
  if (!context) {
    throw new Error("useCommerce must be used within a CommerceProvider");
  }
  return context;
};

export const CommerceWidget = ({
  params,
  onClose,
  onSuccess,
}: {
  params: checkout.CommerceWidgetParams;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const { commerceWidget } = useCommerce();

  useEffect(() => {
    if (!commerceWidget) return;
    commerceWidget.mount("no-thanks", params);
    commerceWidget.addListener(checkout.CommerceEventType.CLOSE, onClose);
    commerceWidget.addListener(checkout.CommerceEventType.SUCCESS, onSuccess);
    return () => {
      commerceWidget.removeListener(checkout.CommerceEventType.SUCCESS);
      commerceWidget.removeListener(checkout.CommerceEventType.CLOSE);
      commerceWidget.unmount();
    };
  }, [params, onClose, onSuccess, commerceWidget]);

  return <div id="no-thanks" />;
};
